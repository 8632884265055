"use client"

import type { ComponentProps, FunctionComponent } from "react"

import { QueryClientProvider } from "@tanstack/react-query"
import { useState } from "react"

import { TranslationProvider } from "@/modules/i18n/components/TranslationProvider"
import { CheckCdnAccess } from "@/modules/JobteaserCdn/CheckCdnAccess"
import { createComponentQueryClient } from "@/modules/queryClient/createComponentQueryClient"

type AppCheckCdnAccessProps = ComponentProps<typeof CheckCdnAccess>

export const AppCheckCdnAccess: FunctionComponent<AppCheckCdnAccessProps> = ({
  currentUserRole = "unknown",
  companyPermalink = "unknown",
}) => {
  const [queryClient] = useState(() => createComponentQueryClient())
  return (
    <TranslationProvider>
      <QueryClientProvider client={queryClient}>
        <CheckCdnAccess currentUserRole={currentUserRole} companyPermalink={companyPermalink} />
      </QueryClientProvider>
    </TranslationProvider>
  )
}
