"use client"

import type { Notification, NotificationId, NotificationParams } from "@/modules/notification/types"
import type { FunctionComponent, PropsWithChildren } from "react"

import dynamic from "next/dynamic"
import { useCallback, useMemo, useRef, useState } from "react"

import { wait } from "@/modules/client/time/wait"
import { DEFAULT_NOTIFICATION_DURATION } from "@/modules/notification/constants"
import { NotificationContext } from "@/modules/notification/Contexts/NotificationContext"
import { generateNotificationId } from "@/modules/notification/utils"

const DynamicNotificationsPanel = dynamic(
  () => import("@/modules/notification/Components/NotificationsPanel").then(mod => mod.NotificationsPanel),
  { ssr: false }
)

export const NotificationProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [notificationList, setNotificationList] = useState<Notification[]>([])

  const removeNotification = useCallback(
    (id: NotificationId) =>
      setNotificationList(notifications => notifications.filter(notification => notification.id !== id)),
    [setNotificationList]
  )

  const [isHovered, setIsHovered] = useState(false)
  // Using Ref to be able to access the latest version of the state inside a callback
  const isHoveredRef = useRef(false)
  isHoveredRef.current = isHovered

  /**
   * Triggers a notification
   * @param title - Title of the notification
   * @param content - Content of the notification
   * @param variant - Variant of the notification (primary, neutral, success, warning, error)
   * @param duration - Duration of the notification in ms
   */
  const showNotification = useMemo(
    () => async (params: NotificationParams) => {
      const { name, title, closeLabel, content, variant = "info", duration = DEFAULT_NOTIFICATION_DURATION } = params
      // Generate unique id
      const id = name || generateNotificationId()
      // Add new notification to the list
      const newNotification: Notification = { closeLabel, content, duration, id, title, variant }
      setNotificationList(notifications => [newNotification, ...notifications])
      // Wait for the duration
      await wait(duration)
      // Freeze the notification if the user is hovering it
      while (isHoveredRef.current) {
        // eslint-disable-next-line no-await-in-loop
        await wait(duration)
      }
      // Remove the notification
      removeNotification(id)
    },
    [removeNotification]
  )

  return (
    <NotificationContext.Provider
      value={useMemo(
        () => ({
          notificationList,
          removeNotification,
          setIsHovered,
          showNotification,
        }),
        [notificationList, showNotification, removeNotification, setIsHovered]
      )}
    >
      {children}
      {!!notificationList.length && <DynamicNotificationsPanel />}
    </NotificationContext.Provider>
  )
}
