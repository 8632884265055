"use client"

import type { FormDataType } from "@/modules/featureEnv/types"
import type { FunctionComponent } from "react"

import classNames from "classnames"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"

import { Divider } from "@jobteaser/spark/components/Divider"

import { DebugInfoDisplay } from "@/modules/debug/components/DebugInfoDisplay"
import { FE_MONOLITH_DURATION_SHORT, FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY } from "@/modules/featureEnv/constants"
import {
  getMonolithFeatureEnvInfos,
  getNextFeatureEnvIdentifier,
  getSpaFeatureEnvIdentifier,
} from "@/modules/featureEnv/utils/client/featureEnvUtils"
import { isI18nDebugMode } from "@/modules/featureEnv/utils/client/i18nDebugUtils"

import styles from "./DebugMenu.module.css"

type DebugMenuProps = {
  requestId: string
  grafanaDebugUrl: string
}

const DynamicFeatureEnvForm = dynamic(() =>
  import("@/modules/featureEnv/Components/FeatureEnvForm").then(mod => mod.FeatureEnvForm)
)

const DebugMenu: FunctionComponent<DebugMenuProps> = ({ requestId, grafanaDebugUrl }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isFormHidden, setIsFormHidden] = useState(true)
  const [featureEnvFormData, setFeatureEnvFormData] = useState<FormDataType>({
    i18nDebugMode: false,
    monolithFeatureEnvDuration: FE_MONOLITH_DURATION_SHORT,
    monolithFeatureEnvIdentifier: "",
    nextFeatureEnvIdentifier: "",
    spaFeatureEnvIdentifier: "",
  })
  const [keysPressed, setKeysPressed] = useState("")

  useEffect(() => {
    // Get the feature env settings from the cookies, only on the client side
    setFeatureEnvFormData({
      i18nDebugMode: isI18nDebugMode(),
      monolithFeatureEnvDuration: getMonolithFeatureEnvInfos()?.duration || FE_MONOLITH_DURATION_SHORT,
      monolithFeatureEnvIdentifier: getMonolithFeatureEnvInfos()?.identifier || "",
      nextFeatureEnvIdentifier: getNextFeatureEnvIdentifier() || "",
      spaFeatureEnvIdentifier: getSpaFeatureEnvIdentifier() || "",
    })
    setIsCollapsed(window.localStorage.getItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY) === "true")
  }, [])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent): void => {
      let newKeysPressed = keysPressed + event.key

      // Check if the last key is 'Escape'
      if (event.key === "Escape") {
        setIsFormHidden(true)
        newKeysPressed = ""
      }

      // Check if the last two characters are 'fe' and if the focus is not on an input
      if (
        newKeysPressed.slice(-2) === "fe" &&
        !(event.target as HTMLElement).nodeName.match(/input|textarea|select/i)
      ) {
        setIsFormHidden(false)
        setIsCollapsed(false)
        window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, "false")
        newKeysPressed = ""
      }

      // Check if the last two characters are 'ef' and if the focus is not on an input
      if (
        newKeysPressed.slice(-2) === "ef" &&
        !(event.target as HTMLElement).nodeName.match(/input|textarea|select/i)
      ) {
        setIsFormHidden(true)
        setIsCollapsed(true)
        window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, "true")
        newKeysPressed = ""
      }

      setKeysPressed(newKeysPressed)
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [keysPressed, isCollapsed])

  const statusButtonContent = `
    ${featureEnvFormData.monolithFeatureEnvIdentifier || process.env.NEXT_PUBLIC_ENV || "staging"}
    ${featureEnvFormData.spaFeatureEnvIdentifier ? ` / ${featureEnvFormData.spaFeatureEnvIdentifier.slice(0, 8)}` : ""}
    ${featureEnvFormData.nextFeatureEnvIdentifier ? ` / ${featureEnvFormData.nextFeatureEnvIdentifier}` : ""}
  `

  const handleStatusButtonClick = (): void => {
    setIsCollapsed(prevState => !prevState)
    window.localStorage.setItem(FE_SWITCHER_MINIMIZE_LOCAL_STORAGE_KEY, String(!isCollapsed))
  }

  return (
    <div
      className={classNames(styles.main, {
        [styles.main__collapsed]: isCollapsed,
      })}
    >
      {isFormHidden && (
        <button
          className={classNames(styles.button, styles.buttonStatus)}
          type="button"
          title="Click to reduce / expand this info"
          onClick={handleStatusButtonClick}
        >
          {statusButtonContent}
        </button>
      )}
      {!isFormHidden && (
        <div className={styles.content}>
          <h3>Debug menu</h3>
          {["feature", "staging"].includes(process.env.NEXT_PUBLIC_ENV || "") && (
            <>
              <DynamicFeatureEnvForm featureEnvFormData={featureEnvFormData} />
              <Divider onBackground="dark" />
            </>
          )}
          <DebugInfoDisplay requestId={requestId} grafanaDebugUrl={grafanaDebugUrl} />
        </div>
      )}
      {!isCollapsed && (
        <button
          className={classNames(styles.button, styles.buttonEdit)}
          type="button"
          title="Edit Feature env settings - PROTIP: use keyboard shortcut 'fe' / 'ef / 'Escape' to display / hide it"
          onClick={() => setIsFormHidden(prevState => !prevState)}
        />
      )}
    </div>
  )
}

// We need a default export for next/dynamic inside App Router
// eslint-disable-next-line import/no-default-export
export default DebugMenu
