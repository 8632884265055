"use client"

import type { Notification, RemoveNotification, SetIsHovered, ShowNotification } from "@/modules/notification/types"

import { useContext } from "react"

import { NotificationContext } from "@/modules/notification/Contexts/NotificationContext"

// Show a notification
export const useNotification = (): ShowNotification => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error(`useNotification must be used within a NotificationProvider`)
  }
  return context.showNotification
}

// Remove a notification
export const useNotificationRemoval = (): RemoveNotification => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error("useNotificationRemoval must be used within a NotificationProvider")
  }
  return context.removeNotification
}

// Set the isHovered state
export const useNotificationSetIsHovered = (): SetIsHovered => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error("useNotificationSetIsHovered must be used within a NotificationProvider")
  }
  return context.setIsHovered
}

// Get the full notifications list
export const useNotificationsList = (): Notification[] => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error("useNotificationsList must be used within a NotificationProvider")
  }
  return context.notificationList
}
