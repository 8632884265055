"use client"

import type { NotificationParams } from "@/modules/notification/types"
import type { FunctionComponent } from "react"

import { useEffect } from "react"

import { Link } from "@jobteaser/spark/components/Link"
import { Text } from "@jobteaser/spark/components/Text"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"
import { logger } from "@/modules/monitoring/logger"
import { useNotification, useNotificationsList } from "@/modules/notification/hooks"

import styles from "./CheckCdnAccess.module.css"

const NOTIFICATION_NAME = "cdn-error-notification"
const ROLES_WITHOUT_NOTIFICATION = ["anonymous", "student"]

const shouldDisplayNotification = (ignoreResponse: boolean, currentUserRole?: string): boolean => {
  return !ignoreResponse && !!currentUserRole && !ROLES_WITHOUT_NOTIFICATION.includes(currentUserRole)
}

type CheckCdnAccessProps = {
  currentUserRole?: string
  companyPermalink?: string | null
}

export const CheckCdnAccess: FunctionComponent<CheckCdnAccessProps> = ({ currentUserRole, companyPermalink }) => {
  const showNotification = useNotification()
  const notificationsList = useNotificationsList()
  const locale = useLocale()
  const { t, tReact, ready } = useTranslation(["shared_modal", "shared_cdn_notification"], true)

  let localeToHelpDeskLocale: string

  switch (locale) {
    case "fr":
    case "de":
    case "es":
      localeToHelpDeskLocale = locale
      break
    case "ca":
      localeToHelpDeskLocale = "es"
      break
    default:
      localeToHelpDeskLocale = "en-gb"
      break
  }

  const faqHref = `https://helpcenter.jobteaser.com/hc/${localeToHelpDeskLocale}/articles/14762291331484-CDN-Error`

  useEffect(() => {
    if (!ready) {
      return
    }

    let ignoreResponse = notificationsList.some(({ name }) => name === NOTIFICATION_NAME)
    const content = (
      <div className={styles.main}>
        <Text variant="body1" weight="regular">
          {tReact("shared_cdn_notification.body", {
            link: (
              <Link title="Help Center page - open in new tab" target="_blank" rel="noopener noreferrer" href={faqHref}>
                {t("shared_cdn_notification.link_cdn_error_article")}
              </Link>
            ),
            locale,
          })}
        </Text>
      </div>
    )
    const notificationParams: NotificationParams = {
      closeLabel: t("shared_modal.close"),
      content,
      duration: 60 * 60 * 1000, // 1 hour
      name: NOTIFICATION_NAME,
      title: t("shared_cdn_notification.title"),
      variant: "warning",
    }

    const logMessage = `Unreachable jobteasercdn asset from "${window.location.hostname}" for role "${
      currentUserRole || "unknown"
    }" and companyPermalink "${companyPermalink || "unknown"}" (HREF ${window.location.href})`
    let hasError = false

    // eslint-disable-next-line no-restricted-globals
    fetch("https://static-assets.jobteasercdn.com/next-jobteaser/production/favicon.ico?cachebust=1")
      .then(res => {
        if (!res.ok) {
          logger.error(`${logMessage} - status ${res.status}`)
          hasError = true
        }
      })
      .catch(err => {
        logger.error(`${logMessage} - err ${err.message}`)
        hasError = true
      })
      .finally(() => {
        if (hasError && shouldDisplayNotification(ignoreResponse, currentUserRole)) {
          showNotification(notificationParams)
        }
      })

    return () => {
      ignoreResponse = true
    }
  }, [currentUserRole, ready])

  return null
}
